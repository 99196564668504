import React, { useEffect, useMemo, useState } from "react"
import Layout from "../../components/layout/layout"
import SEOnew from "../../components/seo-new"
import { graphql, useStaticQuery } from "gatsby"
import "./portfolio-page-styles.css"
import PageSelection from "../../components/common/PageSelection"
import CommitmentSignature from "../../components/sections/CommitmentSignature"
import ContactUsCompact from "../../components/sections/ContactUsCompact"
import PortfolioGridItems from "../../components/common/PortfolioGridItems"
import useWindowSize from "../../utils/useWindowSize"
import { convertToBgImage } from "gbimage-bridge"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import HeroSectionWithTitle from "../../components/common/HeroSectionWithTitle"
import _ from "lodash"

const PortfolioPage = ({ location }) => {
  const screen = useWindowSize()
  const [gridView, setGridView] = useState(true)
  const [selectedPortfolio, setSelectedPortfolio] = useState([])
  const [selectedCategory, setSelectedCategory] = useState("")
  const [searchKey, setSearchKey] = useState("")

  const prismicPortfolios = useStaticQuery(graphql`
    query PortfoliosPageQuery {
      allPrismicBasePage(filter: { uid: { eq: "portfolios" } }) {
        edges {
          node {
            data {
              title
              hero_image {
                fluid {
                  src
                }
                gatsbyImageData(width: 1000, placeholder: BLURRED)
              }
            }
          }
        }
      }
      prismicPortfolioOrder {
        data {
          ordering {
            portfolio {
              uid
            }
          }
        }
      }
      prismicGeneralSettings {
        data {
          portfolio_category_tags {
            category_tag
          }
        }
      }
      allPrismicPortfolio(sort: { fields: data___date, order: DESC }) {
        nodes {
          uid
          tags
          data {
            title
            summary
            location
            date
            preview_image {
              alt
              gatsbyImageData(
                width: 450
                placeholder: BLURRED
                imgixParams: { auto: "false" }
              )
            }
          }
        }
        distinct(field: tags)
      }
    }
  `)

  const origPortfolios = useMemo(() => {
    if (prismicPortfolios) {
      const sortKey = prismicPortfolios.prismicPortfolioOrder.data.ordering.map(
        order => {
          return order.portfolio.uid
        }
      )
      let counter = 0

      const data = prismicPortfolios.allPrismicPortfolio.nodes.map(node => {
        const { uid, data, tags } = node
        const { title, date, preview_image, summary, location } = data
        const index = _.indexOf(sortKey, uid)
        counter++
        return {
          uid,
          title,
          summary,
          location: location ? location : "",
          date: new Date(date),
          image: preview_image,
          image_alt: preview_image.alt ?? title,
          tags,
          index: index >= 0 ? index : 99999,
        }
      })

      const sorted = _.orderBy(data, ["index","date"], ["asc","desc"])
      return sorted
    }

    return []
  }, [prismicPortfolios])

  const categories = useMemo(() => {
    const categoryOrder = prismicPortfolios.prismicGeneralSettings.data.portfolio_category_tags.map(category => category.category_tag);
    categoryOrder.unshift("All");

    const data = [
      {
        value: "all",
        label: "All",
      },
    ]

    if (prismicPortfolios) {
      for (const tag of prismicPortfolios.allPrismicPortfolio.distinct) {
        data.push({
          value: tag.toLowerCase(),
          label: tag,
        })
      }
    }

    const sorted = _.sortBy(data, category => {
      var index = _.indexOf(categoryOrder, category.label);
      return  index >= 0 ? index : 9999;
    })

    const params = new URLSearchParams(location.search)
    const categoryParam =
      params.get("category") === "European_Mediterranean"
        ? "European & Mediterranean"
        : params.get("category")

    setSelectedCategory(categoryParam ? categoryParam : sorted[0].label)
    return sorted
  }, [prismicPortfolios])

  useEffect(() => {
    setSelectedPortfolio(origPortfolios)
  }, [])

  useEffect(() => {
    let filtered = [...origPortfolios]
    if (selectedCategory) {
      if (selectedCategory !== "All") {
        filtered = filtered.filter(p => p.tags.includes(selectedCategory))
      }
    }

    if (searchKey) {
      filtered = filtered.filter(portfolio => {
        return (
          portfolio.summary?.toLowerCase().includes(searchKey.toLowerCase()) ||
          portfolio.title.toLowerCase().includes(searchKey.toLowerCase()) ||
          portfolio.location?.toLowerCase().includes(searchKey.toLowerCase())
        )
      })
    }

    setSelectedPortfolio(filtered)
  }, [selectedCategory, searchKey])

  const heroImage = getImage(
    prismicPortfolios.allPrismicBasePage.edges[0].node.data.hero_image
  )

  return (
    <Layout>
      <SEOnew
        title={prismicPortfolios.allPrismicBasePage?.edges[0]?.node.data.title}
      />
      <div className="w-full relative flex flex-col items-center justify-center">
        <HeroSectionWithTitle
          title={
            prismicPortfolios.allPrismicBasePage?.edges[0]?.node.data.title
          }
          description={
            "Brion Jeannette Architecture’s portfolio demonstrates their skill creating homes that enhance the quality of life for each client’s unique design interests while maximizing the benefits and opportunities of each site."
          }
          image={heroImage}
          textLocation="left"
          imagePosition="bottom left"
        />
      </div>
      <div className="flex w-full flex-col pt-2 mb-12 min-h-screen items-center">
        <div className="w-full max-w-5xl flex flex-col items-center justify-center">
          <div
            className="bg-gray-100 w-full max-w-5xl py-6 shadow-lg"
            style={{ borderRadius: "8px" }}
          >
            <PageSelection
              categories={categories}
              onCategorySelect={setSelectedCategory}
              gridView={gridView}
              onViewChange={() => setGridView(!gridView)}
              onSearch={setSearchKey}
              selectedCategory={selectedCategory}
            />
          </div>
        </div>
        <div className="w-full max-w-7xl px-4">
          <PortfolioGridItems grid={gridView} items={selectedPortfolio} />
        </div>
      </div>
      <ContactUsCompact />
      <CommitmentSignature />
    </Layout>
  )
}

export default PortfolioPage

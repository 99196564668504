import { bottom } from "@popperjs/core"
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image"
import React, { useEffect, useMemo, useState } from "react"
import styled from "styled-components"
import useWindowScroll from "../../../utils/useWindowScroll"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"

const TitleDiv = styled.div`
  font-size: 1.25rem;
  background-color: rgba(0, 0, 0, 0.5);
`

const LocationDiv = styled.div`
  font-size: 0.8rem;
`

const PortfolioGridItems = ({ grid = true, items = [] }) => {
  const [hidePic, setHidePic] = useState(false)
  const winScroll = useWindowScroll()
  const [loadBelowFold, setLoadBelowFold] = useState(false)

  const sortedItems = useMemo(() => {
    let sorted = items.sort((a, b) => b.date - b.date)

    if (!loadBelowFold) {
      sorted = sorted.slice(0, 20)
    }

    return sorted
  }, [items, loadBelowFold])

  useEffect(() => {
    if (winScroll && winScroll.scrollY > 100 && !loadBelowFold) {
      setLoadBelowFold(true)
    }
  }, [winScroll.scrollY])

  // useEffect(() => {
  //   function hide() {
  //     setHidePic(true)
  //   }

  //   function show(delay = 500) {
  //     setTimeout(() => {
  //       setHidePic(false)
  //     }, delay)
  //   }
  //   function handleKeyUp(e) {
  //     if (e.key == "PrintScreen") {
  //       hide()
  //       show(1000);
  //     }
  //   }

  //   function handleKeyDown(e) {
  //     if (e.key == "Meta" || e.key == "Alt") {
  //       hide()
  //     }
  //   }

    

  //   window.addEventListener("keyup", handleKeyUp)
  //   window.addEventListener("keydown", handleKeyDown)
  //   window.addEventListener("blur", hide)
  //   window.addEventListener("focus", show)
  //   return () => {
  //     window.removeEventListener("keyup", handleKeyUp)
  //     window.removeEventListener("keydown", handleKeyDown)
  //     window.removeEventListener("blur", hide)
  //     window.removeEventListener("focus", show)
  //   }
  // }, [])

  return (
    <>
      {grid ? (
        <div className="w-full grid grid-cols-1 sm:grid-cols-4 gap-x-2 gap-y-8 py-12 relative">
          {sortedItems.map((item, index) => {
            const image = getImage(item.image)
            const bgImage = convertToBgImage(image)
            return (
              <div
                className="w-full h-56 relative flex portfolio-page-item"
                style={{ animationDelay: `${0.5 + index * 0.2}s` }}
                key={`portfolio-grid-${index}-${item.uid}`}
              >
                <StaticImage
                  src="../../../images/blank.png"
                  width={155}
                  quality={95}
                  placeholder="DOMINANT_COLOR"
                  formats={["AUTO", "WEBP"]}
                  alt={`Portfolio${index}-${item.uid}`}
                  className="z-30 w-full h-full absolute"
                  style={{position: "absolute"}}
                  objectFit="cover"
                />
                {/* <BackgroundImage
                  Tag="section"
                  {...bgImage}
                  preserveStackingContext
                  className="w-full h-full z-20 restrict"
                  style={
                    hidePic
                      ? { display: "none", borderRadius: "5px" }
                      : { display: "block", borderRadius: "5px" }
                  }
                >
                  &nbsp;
                </BackgroundImage> */}
                <GatsbyImage
                  className="w-full h-full z-20"
                  alt={item.item_alt}
                  style={{ borderRadius: "5px" }}
                  image={image}
                />
                <div className="absolute w-full h-full z-10 bg-white">
                  &nbsp;
                </div>
                <div className="absolute w-full h-full flex justify-center items-end">
                  <div
                    className="w-full"
                    style={{
                      borderRadius: "100%",
                      width: "80%",
                      height: "100px",
                      boxShadow: "0px 5px 24px 10px black",
                    }}
                  >
                    &nbsp;
                  </div>
                </div>
                <a
                  href={`/portfolios-details/${item.uid}`}
                  className="portfolio-page-gridItem"
                >
                  <TitleDiv className="text-center w-full px-2 py-4 uppercase">
                    {item.title || <>&nbsp;</>}
                    {item.location && (
                      <LocationDiv className="text-center w-full text-gray-300">
                        {item.location}
                      </LocationDiv>
                    )}
                  </TitleDiv>
                </a>
              </div>
            )
          })}
        </div>
      ) : (
        <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-x-8 gap-y-8 md:gap-y-12 py-12 relative justify-center justify-items-center">
          {sortedItems.map((item, index) => {
            const image = getImage(item.image)

            return (
              <div
                className="w-full relative flex flex-col md:flex-row portfolio-page-item"
                style={{
                  animationDelay: `${0.5 + index * 0.2}s`,
                  boxShadow: "0px 8px 15px 0px #333333A0",
                }}
                key={`portfolio-list-${index}-${item.uid}`}
              >
                <div className="h-full w-full md:w-2/5 flex justify-center items-center pl-2">
                  <GatsbyImage
                    className="w-60 md:w-56 h-36 md:h-32 object-cover z-20"
                    alt={item.item_alt}
                    style={{ borderRadius: "5px" }}
                    image={image}
                  />
                </div>
                <div className="flex flex-grow flex-col justify-center px-4 py-8">
                  <span className="font-semibold mb-1">
                    {item.title || <>&nbsp;</>}
                  </span>
                  {item.location && (
                    <span className="text-gray-500 text-sm">
                      {item.location}
                    </span>
                  )}
                  <span className="text-gray-500 text-sm mt-2">
                    {item.summary}
                  </span>
                  <a
                    href={`/portfolios-details/${item.uid}`}
                    className="text-sm text-site-yellow mt-6"
                  >
                    See more...
                  </a>
                </div>
              </div>
            )
          })}
        </div>
      )}
    </>
  )
}

export default PortfolioGridItems
